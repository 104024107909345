@tailwind base;
@tailwind components;
@tailwind utilities;


.simple-keyboard .hg-button.zy-minus {
  padding-right: 8px;
  flex-grow: 0;
  padding-left: 8px;
}

.simple-keyboard .zy.zy-comma span {
  line-height: 0.5rem;
}

.simple-keyboard .zy.zy-period span {
  line-height: 0.5rem;
}

.simple-keyboard  .hg-button.hg-button-numbers {
  flex-grow: 0;
  opacity: 0.3;
}
.simple-keyboard  .hg-button.hg-button-ent {
  flex-grow: 0;
  opacity: 0.3;
}

.simple-keyboard .zy:before {
  display: inline-block;
}

.simple-keyboard .zy {
  position: relative;
}

.simple-keyboard .zy span {
  position: absolute;
  top: -1px;
  right: 2px;
  font-size: 0.75rem;
  line-height: 1rem;
}

.simple-keyboard .zy-1:before {
  content: "ㄅ";
}


.simple-keyboard .zy-q:before {
  content: "ㄆ";
}


.simple-keyboard .zy-a:before {
  content: "ㄇ";
}


.simple-keyboard .zy-z:before {
  content: "ㄈ";
}


.simple-keyboard .zy-2:before {
  content: "ㄉ";
}


.simple-keyboard .zy-w:before {
  content: "ㄊ";
}


.simple-keyboard .zy-s:before {
  content: "ㄋ";
}


.simple-keyboard .zy-x:before {
  content: "ㄌ";
}


.simple-keyboard .zy-e:before {
  content: "ㄍ";
}


.simple-keyboard .zy-d:before {
  content: "ㄎ";
}


.simple-keyboard .zy-c:before {
  content: "ㄏ";
}


.simple-keyboard .zy-r:before {
  content: "ㄐ";
}


.simple-keyboard .zy-f:before {
  content: "ㄑ";
}


.simple-keyboard .zy-v:before {
  content: "ㄒ";
}


.simple-keyboard .zy-5:before {
  content: "ㄓ";
}


.simple-keyboard .zy-t:before {
  content: "ㄔ";
}


.simple-keyboard .zy-g:before {
  content: "ㄕ";
}


.simple-keyboard .zy-b:before {
  content: "ㄖ";
}


.simple-keyboard .zy-y:before {
  content: "ㄗ";
}


.simple-keyboard .zy-h:before {
  content: "ㄘ";
}


.simple-keyboard .zy-n:before {
  content: "ㄙ";
}


.simple-keyboard .zy-8:before {
  content: "ㄚ";
}


.simple-keyboard .zy-i:before {
  content: "ㄛ";
}


.simple-keyboard .zy-k:before {
  content: "ㄜ";
}


.simple-keyboard .zy-comma:before {
  content: "ㄝ";
}


.simple-keyboard .zy-9:before {
  content: "ㄞ";
}


.simple-keyboard .zy-o:before {
  content: "ㄟ";
}


.simple-keyboard .zy-l:before {
  content: "ㄠ";
}


.simple-keyboard .zy-period:before {
  content: "ㄡ";
}


.simple-keyboard .zy-0:before {
  content: "ㄢ";
}


.simple-keyboard .zy-p:before {
  content: "ㄣ";
}


.simple-keyboard .zy-semicolon:before {
  content: "ㄤ";
}


.simple-keyboard .zy-slash:before {
  content: "ㄥ";
}


.simple-keyboard .zy-minus:before {
  content: "ㄦ";
}


.simple-keyboard .zy-u:before {
  content: "ㄧ";
}


.simple-keyboard .zy-j:before {
  content: "ㄨ";
}


.simple-keyboard .zy-m:before {
  content: "ㄩ";
}


.simple-keyboard .zy-3:before {
  content: "ˇ";
}


.simple-keyboard .zy-4:before {
  content: "ˋ";
}


.simple-keyboard .zy-6:before {
  content: "ˊ";
}


.simple-keyboard .zy-7:before {
  content: "˙";
}